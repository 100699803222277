<template>
  <div class="map" ref="map"></div>
</template>

<script>
  import * as am4core from "@amcharts/amcharts4/core";
  import * as am4maps from "@amcharts/amcharts4/maps";
  import am4themes_material from "@amcharts/amcharts4/themes/material";
  import am4geodata_worldLow from "@amcharts/amcharts4-geodata/worldLow";
  import MapSettings from "@/map-settings";

  export default {
    name: "Map",
    props: {
      data: Array,
    },
    data() {
      return {
        map: null,
        heatLegend: null,
        polygonSeries: null,
        legendTooltipSetTimeout: null,
        countries: this.data,
      };
    },
    mounted() {
      // Applying themes
      am4core.useTheme(am4themes_material);

      // Initiate map
      this.map = am4core.create(this.$refs.map, am4maps.MapChart);
      this.map.geodata = am4geodata_worldLow;
      this.map.projection = new am4maps.projections.Miller();
      this.map.zoomStep = 1.5;

      // Legend & zoom container
      const legendContainer = this.map.chartContainer.createChild(
        am4core.Container
      );
      legendContainer.config = MapSettings.legendContainer;
      legendContainer.padding(12, 12, 12, 12);

      const zoomContainer = this.map.chartContainer.createChild(
        am4core.Container
      );
      zoomContainer.config = MapSettings.zoomContainer;
      zoomContainer.padding(0, 0, 0, 0);

      // Setup zoom controls
      this.map.zoomControl = new am4maps.ZoomControl();
      this.map.zoomControl.parent = zoomContainer;
      this.map.zoomControl.padding(0, 0, 0, 0);

      this.map.zoomControl.plusButton.cursorOverStyle =
        am4core.MouseCursorStyle.pointer;
      this.map.zoomControl.plusButton.toBack();
      this.map.zoomControl.plusButton.padding(0, 0, 0, 0);
      this.map.zoomControl.plusButton.background.cornerRadius(2, 2, 2, 2);
      this.map.zoomControl.plusButton.background.states.getKey(
        "hover"
      ).properties.fill = MapSettings.colors.buttonHover;
      this.map.zoomControl.plusButton.background.states.getKey(
        "down"
      ).properties.fill = MapSettings.colors.buttonHover;
      this.map.zoomControl.plusButton.icon = new am4core.Sprite();

      this.map.zoomControl.minusButton.cursorOverStyle =
        am4core.MouseCursorStyle.pointer;
      this.map.zoomControl.minusButton.background.cornerRadius(2, 2, 2, 2);
      this.map.zoomControl.minusButton.padding(0, 0, 0, 0);
      this.map.zoomControl.minusButton.toFront();
      this.map.zoomControl.minusButton.background.states.getKey(
        "hover"
      ).properties.fill = MapSettings.colors.buttonHover;
      this.map.zoomControl.minusButton.background.states.getKey(
        "down"
      ).properties.fill = MapSettings.colors.buttonHover;

      this.map.zoomControl.thumb.cursorOverStyle =
        am4core.MouseCursorStyle.pointer;
      this.map.zoomControl.thumb.background.cornerRadius(2, 2, 2, 2);
      this.map.zoomControl.thumb.background.states.getKey(
        "hover"
      ).properties.fill = MapSettings.colors.buttonHover;
      this.map.zoomControl.thumb.background.states.getKey(
        "down"
      ).properties.fill = MapSettings.colors.buttonHover;
      this.map.zoomControl.config = MapSettings.zoomConfig;

      // Add button to zoom out
      const zoomHome = zoomContainer.createChild(am4core.Button);
      zoomHome.icon = new am4core.Sprite();
      zoomHome.config = MapSettings.zoomHome;
      zoomHome.padding(0, 0, 0, 0);
      zoomHome.cursorOverStyle = am4core.MouseCursorStyle.pointer;
      zoomHome.background.cornerRadius(2, 2, 2, 2);

      zoomHome.background.states.getKey("hover").properties.fill =
        MapSettings.colors.buttonHover;
      zoomHome.background.states.getKey("down").properties.fill =
        MapSettings.colors.buttonHover;

      zoomHome.events.on("hit", () => {
        this.map.goHome();
      });

      // Create countries map series
      this.polygonSeries = this.map.series.push(new am4maps.MapPolygonSeries());

      this.polygonSeries.include = this.countriesMapSeries;
      this.polygonSeries.data = this.countries;
      this.polygonSeries.exclude = MapSettings.disabledCountries;

      // Create polygon template
      const polygonTemplate = this.polygonSeries.mapPolygons.template;
      polygonTemplate.tooltip = new am4core.Tooltip();
      polygonTemplate.cursorOverStyle = am4core.MouseCursorStyle.pointer;

      polygonTemplate.config = MapSettings.polygonTemplate;

      this.polygonSeries.heatRules.push({
        property: "fill",
        target: this.polygonSeries.mapPolygons.template,
        min: am4core.color(MapSettings.colors.legendMin),
        max: am4core.color(MapSettings.colors.legendMax),
      });

      this.polygonSeries.useGeodata = true;

      // Heat legend setup
      this.heatLegend = legendContainer.createChild(am4maps.HeatLegend);
      this.heatLegend.toBack();
      this.heatLegend.series = this.polygonSeries;
      this.heatLegend.config = MapSettings.heatLegendConfig;

      // Set up custom heat map legend labels using axis ranges
      const minRange = this.heatLegend.valueAxis.axisRanges.create();
      minRange.label.horizontalCenter = "left";

      const maxRange = this.heatLegend.valueAxis.axisRanges.create();
      maxRange.label.horizontalCenter = "right";

      this.heatLegend.valueAxis.renderer.labels.template.adapter.add(
        "text",
        function() {
          return "";
        }
      );

      const legendLabel = legendContainer.createChild(am4core.Label);
      legendLabel.insertBefore(this.heatLegend);
      legendLabel.config = MapSettings.legendLabel;

      // Update heat legend value labels
      this.polygonSeries.events.on("datavalidated", function(ev) {
        const heatLegend = ev.target.map.getKey(
          MapSettings.heatLegendConfig.id
        );
        const min = heatLegend.series.dataItem.values.value.low;
        const minRange = heatLegend.valueAxis.axisRanges.getIndex(0);
        minRange.value = min;
        minRange.label.text = "" + heatLegend.numberFormatter.format(min);

        const max = heatLegend.series.dataItem.values.value.high;
        const maxRange = heatLegend.valueAxis.axisRanges.getIndex(1);
        maxRange.value = max;
        maxRange.label.text = "" + heatLegend.numberFormatter.format(max);
      });

      polygonTemplate.events.on("hit", (event) => {
        this.handleOver(event.target);
        this.map.zoomToMapObject(event.target, 2);
        this.$emit("toggleCountry", event.target.dataItem.dataContext.id);
      });

      polygonTemplate.events.on("out", () => {
        this.handleOut();
      });

      polygonTemplate.events.on("over", (event) => {
        this.handleOver(event.target);
        event.target.zIndex = Number.MAX_VALUE;
        event.target.toFront();
      });

      const activeState = polygonTemplate.states.create("active");
      activeState.properties.fill = MapSettings.colors.countryActive;

      const hoverState = polygonTemplate.states.create("hover");
      hoverState.properties.fill = MapSettings.colors.countryHover;

      const activeHoverState = polygonTemplate.states.create("hoverActive");
      activeHoverState.properties.fill = MapSettings.colors.countryHoverActive;

      // Create map for countries not in the data
      const worldSeries = this.map.series.push(new am4maps.MapPolygonSeries());
      worldSeries.useGeodata = true;
      worldSeries.exclude = this.countriesMapSeries.concat(
        MapSettings.disabledCountries
      );
      worldSeries.fillOpacity = 1;
      worldSeries.hiddenInLegend = true;
      worldSeries.mapPolygons.template.nonScalingStroke = true;
    },

    computed: {
      countriesMapSeries: function() {
        const countries = [];
        this.countries.forEach(function(country) {
          countries.push(country.id);
        });
        return countries;
      },
    },

    methods: {
      handleOver(mapPolygon) {
        clearTimeout(this.legendTooltipSetTimeout);
        if (!isNaN(mapPolygon.dataItem.value)) {
          this.heatLegend.valueAxis.showTooltipAt(mapPolygon.dataItem.value);
        } else {
          this.heatLegend.valueAxis.hideTooltip();
        }
      },

      handleOut() {
        this.legendTooltipSetTimeout = setTimeout(() => {
          this.heatLegend.valueAxis.hideTooltip();
        }, 1000);
      },

      deselectedAllCoutries() {
        this.polygonSeries.data = this.polygonSeries.data.map((item) => ({
          ...item,
          isActive: false,
        }));
      },

      selectCountry(id) {
        const polygon = this.polygonSeries.getPolygonById(id);
        polygon.isActive = true;
      },

      deselectCountry(id) {
        const polygon = this.polygonSeries.getPolygonById(id);
        polygon.isActive = false;
      },
    },

    beforeDestroy() {
      if (this.map) {
        this.map.dispose();
      }
    },
  };
</script>

<style scoped>
  .map {
    height: 630px;
  }
</style>

<template>
  <div class="selection-wrap">
    <div class="filter">
      <h3 class="filter-title">Filter countries</h3>
      <input
        class="filter-input"
        type="text"
        placeholder="Filter by country"
        v-model="filterInputValue"
      />
    </div>
    <div class="tree">
      <label
        v-for="country in filteredCountries"
        :for="country.id"
        class="tree-item"
        :class="[country.isActive ? '-active' : '']"
        :key="country.id"
      >
        <input
          :id="country.id"
          type="checkbox"
          :checked="country.isActive"
          class="tree-item-checkbox"
          @change="$emit('toggleCountry', country.id)"
        />
        <span v-if="!country.name" class="tree-item-title">
          {{ country.id }}
        </span>
        <span v-else class="tree-item-title">
          {{ country.name }}
        </span>
      </label>
    </div>
  </div>
</template>

<script>
  export default {
    name: "Tree",
    props: {
      countries: Array,
    },
    data() {
      return {
        filterInputValue: "",
      };
    },
    computed: {
      filteredCountries: function() {
        return this.countries.filter((country) => {
          const filterBy = country.name ?? country.id;
          return (
            filterBy
              .toLowerCase()
              .indexOf(this.filterInputValue.toLowerCase()) > -1
          );
        });
      },
    },
  };
</script>

<style scoped>
  .tree {
    max-height: max(50vh, 600px);
    overflow: auto;
  }

  .tree-item {
    display: block;
    padding: var(--spacing-unit-tiny) var(--spacing-unit);
  }

  .tree-item:hover {
    cursor: pointer;
  }
</style>

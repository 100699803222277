<template>
  <main id="app" class="wrapper">
    <aside class="sidebar panel">
      <Tree :countries.sync="countries" @toggleCountry="toggleCountry" />

      <Selection
        @clearAll="clearSelection"
        @deselectCountry="toggleCountry"
        :selection="selection"
      />

      <AdvancedSearch />
    </aside>

    <div class="content">
      <div class="map-wrapper panel">
        <Map :data="countries" ref="map" @toggleCountry="toggleCountry" />
      </div>

      <section class="summary">
        <Summary :title="selectionTitle" @openModal="showMethodology = true" />

        <DataPanel />
      </section>
    </div>

    <transition name="modal">
      <Modal v-if="showMethodology" @close="showMethodology = false" />
    </transition>
  </main>
</template>

<script>
  import countriesData from "./map-data.json";
  import Modal from "./components/Modal";
  import Map from "./components/Map";
  import DataPanel from "./components/DataPanel";
  import AdvancedSearch from "./components/AdvancedSearch.vue";
  import Selection from "./components/Selection.vue";
  import Tree from "./components/Tree.vue";
  import Summary from "./components/Summary.vue";

  export default {
    name: "Dashboard",
    components: {
      Modal,
      DataPanel,
      Map,
      AdvancedSearch,
      Selection,
      Tree,
      Summary,
    },
    data() {
      return {
        showMethodology: false,
        // selection: [],
        countries: countriesData.countries,
      };
    },

    computed: {
      selectionTitle: function() {
        if (!this.selection.length) {
          return "Global";
        } else if (this.selection.length === 1) {
          return this.selection[0].name;
        } else {
          return `${this.selection.length} Countries selected`;
        }
      },
      selection: function() {
        return this.countries.filter((country) => country.isActive);
      },
    },

    methods: {
      clearSelection() {
        // this.selection = [];

        this.countries = this.countries.map((country) => ({
          ...country,
          isActive: false,
        }));

        // Clear map selection
        this.$refs.map.deselectedAllCoutries();
      },

      toggleCountry(id) {
        // const selected = this.countries.find((country) => country.id === id);
        const country = this.countries.find((country) => country.id === id);
        const index = this.countries.indexOf(country);

        if (country.isActive) {
          // this.countries = this.countries.map((country) => {
          //   if (country.id === id) {
          //     country.isActive = false;
          //   }
          //   return country;
          // });
          // this.countries.find((country) => country.id === id).isActive = false;
          // this.$set(this.countries, country, false);
          this.$set(this.countries[index], "isActive", false);
          // this.selection.splice(index, 1);
          this.$refs.map.deselectCountry(id);
        } else {
          // this.countries.find((country) => country.id === id).isActive = true;
          // this.countries = this.countries.map((country) => {
          //   if (country.id === id) {
          //     country.isActive = true;
          //   }
          //   return country;
          // });
          this.$set(this.countries[index], "isActive", true);
          // this.selection.push(selected);
          this.$refs.map.selectCountry(id);
        }
      },
    },
  };
</script>

<style scoped>
  .wrapper {
    max-width: 1440px;
    width: 100%;
    min-width: 1024px;
    display: flex;
    min-height: 100vh;
    margin-right: auto;
    margin-left: auto;
    padding: var(--spacing-unit-small);
  }

  .sidebar {
    padding: sticky;
    bottom: 0;
    flex: 0 0 317px;
    min-width: 0;
    margin-right: var(--spacing-unit-small);
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - 34px);
    min-height: 630px;
  }

  @media screen and (min-height: 662px) {
    .sidebar {
      position: sticky;
      top: var(--spacing-unit-small);
    }
  }

  .content {
    flex: 1 1 auto;
  }

  .map-wrapper {
    position: relative;
    background-color: #ebedf1;
    margin-bottom: var(--spacing-unit-small);
  }
</style>

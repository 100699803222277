<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container">
        <header class="modal-header">
          <h1 class="modal-title">Methodology</h1>
          <button class="modal-close" @click="$emit('close')">×</button>
        </header>
        <div class="content">
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque
            voluptatum, blanditiis ipsum labore ullam quis optio nesciunt vitae
            aliquam, adipisci fugit praesentium amet iste expedita harum
            mollitia! Ratione, porro hic.
          </p>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque
            voluptatum, blanditiis ipsum labore ullam quis optio nesciunt vitae
            aliquam, adipisci fugit praesentium amet iste expedita harum
            mollitia! Ratione, porro hic.
          </p>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque
            voluptatum, blanditiis ipsum labore ullam quis optio nesciunt vitae
            aliquam, adipisci fugit praesentium amet iste expedita harum
            mollitia! Ratione, porro hic.
          </p>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque
            voluptatum, blanditiis ipsum labore ullam quis optio nesciunt vitae
            aliquam, adipisci fugit praesentium amet iste expedita harum
            mollitia! Ratione, porro hic.
          </p>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque
            voluptatum, blanditiis ipsum labore ullam quis optio nesciunt vitae
            aliquam, adipisci fugit praesentium amet iste expedita harum
            mollitia! Ratione, porro hic.
          </p>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque
            voluptatum, blanditiis ipsum labore ullam quis optio nesciunt vitae
            aliquam, adipisci fugit praesentium amet iste expedita harum
            mollitia! Ratione, porro hic.
          </p>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque
            voluptatum, blanditiis ipsum labore ullam quis optio nesciunt vitae
            aliquam, adipisci fugit praesentium amet iste expedita harum
            mollitia! Ratione, porro hic.
          </p>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque
            voluptatum, blanditiis ipsum labore ullam quis optio nesciunt vitae
            aliquam, adipisci fugit praesentium amet iste expedita harum
            mollitia! Ratione, porro hic.
          </p>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque
            voluptatum, blanditiis ipsum labore ullam quis optio nesciunt vitae
            aliquam, adipisci fugit praesentium amet iste expedita harum
            mollitia! Ratione, porro hic.
          </p>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque
            voluptatum, blanditiis ipsum labore ullam quis optio nesciunt vitae
            aliquam, adipisci fugit praesentium amet iste expedita harum
            mollitia! Ratione, porro hic.
          </p>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque
            voluptatum, blanditiis ipsum labore ullam quis optio nesciunt vitae
            aliquam, adipisci fugit praesentium amet iste expedita harum
            mollitia! Ratione, porro hic.
          </p>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque
            voluptatum, blanditiis ipsum labore ullam quis optio nesciunt vitae
            aliquam, adipisci fugit praesentium amet iste expedita harum
            mollitia! Ratione, porro hic.
          </p>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque
            voluptatum, blanditiis ipsum labore ullam quis optio nesciunt vitae
            aliquam, adipisci fugit praesentium amet iste expedita harum
            mollitia! Ratione, porro hic.
          </p>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque
            voluptatum, blanditiis ipsum labore ullam quis optio nesciunt vitae
            aliquam, adipisci fugit praesentium amet iste expedita harum
            mollitia! Ratione, porro hic.
          </p>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque
            voluptatum, blanditiis ipsum labore ullam quis optio nesciunt vitae
            aliquam, adipisci fugit praesentium amet iste expedita harum
            mollitia! Ratione, porro hic.
          </p>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque
            voluptatum, blanditiis ipsum labore ullam quis optio nesciunt vitae
            aliquam, adipisci fugit praesentium amet iste expedita harum
            mollitia! Ratione, porro hic.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "Modal",
    mounted() {
      document.addEventListener("keydown", (e) => {
        if (e.keyCode == 27) {
          this.$emit("close");
        }
      });
    },
    beforeDestroy() {
      document.removeEventListener("keydown");
    },
  };
</script>

<style scoped>
  .modal-mask {
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    display: flex;
    padding: var(--spacing-unit-large);
  }

  .modal-wrapper {
    margin: auto;
    max-height: 100%;
    display: flex;
  }

  .modal-container {
    max-width: 1400px;
    padding: var(--spacing-unit-large);
    padding-top: 0;
    background: var(--color-white);
    overflow: auto;
    overscroll-behavior: contain;
    max-height: 100%;
    transition: all 0.3s ease;
  }

  .modal-header {
    position: sticky;
    top: 0;
    background: var(--color-white);
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0;
    padding: var(--spacing-unit-large) 0;
  }

  .modal-title {
    margin: 0;
  }

  .modal-close {
    border: 1px solid var(--color-gray-300);
    background: transparent;
    height: 32px;
    width: 32px;
    font-size: 24px;
    border-radius: 2px;
  }

  .modal-close:hover {
    color: var(--color-blue-400);
    border-color: currentColor;
    cursor: pointer;
  }

  .modal-enter-active,
  .modal-leave-active {
    transition: opacity 300ms;
  }

  .modal-enter,
  .modal-leave-to {
    opacity: 0;
  }
</style>

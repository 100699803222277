<template>
  <div class="data">
    <div class="data-cards">
      <div class="card">
        <div class="card-title">Total values</div>
        <div class="card-value">
          24 <span class="card-value-suffix">USDmn</span>
        </div>
      </div>
      <div class="card">
        <div class="card-title">Number of Projects</div>
        <div class="card-value">432</div>
      </div>
      <div class="card">
        <div class="card-title">Industry Risk/Reward Index</div>
        <div class="card-value">3427098</div>
      </div>
      <div class="card">
        <div class="card-title">Project Risk Index</div>
        <div class="card-value">3427098</div>
      </div>
    </div>
    <div class="data-sectors">
      <h4 class="sectors-title">Sectors</h4>
      <div class="sector">
        <div class="sector-title">Construction</div>
        <span class="sector-value">234</span>
      </div>
      <div class="sector">
        <div class="sector-title">Energy & Utilities</div>
        <span class="sector-value">234</span>
      </div>
      <div class="sector">
        <div class="sector-title">Social Infrastructure</div>
        <span class="sector-value">234</span>
      </div>
      <div class="sector">
        <div class="sector-title">Transport</div>
        <span class="sector-value">234</span>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "DataPanel",
  };
</script>

<style scoped>
  .data {
    display: flex;
  }

  .data-cards {
    display: grid;
    grid-gap: 16px;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    flex: 1 1 auto;
  }

  .card {
    border: 1px solid var(--color-gray-300);
    border-radius: 2px;
    padding: var(--spacing-unit);
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
  }

  .card:hover {
    background-color: #f3faff;
    border-color: var(--color-blue-100);
    cursor: pointer;
  }

  .card:hover .card-value {
    color: var(--color-blue-400);
  }

  .card-title {
    color: #555;
    font-weight: 600;
    margin-bottom: var(--spacing-unit-small);
  }

  .card-value {
    font-size: 32px;
    font-weight: 700;
    color: var(--color-blue-100);
  }

  .card-value-suffix {
    color: #555;
    font-size: 18px;
    /* margin-left: var(--spacing-unit-small); */
  }

  .data-sectors {
    flex: 0 1 40%;
    margin-left: var(--spacing-unit-tiny);
    border: 1px solid var(--color-gray-300);
    border-radius: 2px;
    padding: var(--spacing-unit);
  }

  .sectors-title {
    margin: 0 0 var(--spacing-unit-small);
    font-size: 16px;
    line-height: 1.1;
    font-weight: 700;
  }

  .sector {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: var(--spacing-unit-tiny) 0;
    line-height: 1.375;
  }

  .sector:hover {
    cursor: pointer;
    color: var(--color-blue-400);
  }

  .sector:hover .sector-title,
  .sector:hover .sector-value {
    color: inherit;
  }

  .sector + .sector {
    border-top: 1px solid var(--color-gray-300);
  }

  .sector-value {
    font-weight: 700;
    color: var(--color-blue-100);
  }
</style>

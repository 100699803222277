<template>
  <div class="advanced-search">
    <h4 class="advanced-search-title">Advanced Search</h4>
    <p>
      Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quidem
      perspiciatis dolorem quam totam
    </p>
    <button class="advanced-search-button">Go to Advanced Search</button>
  </div>
</template>

<script>
  export default {
    name: "AdvancedSearch",
  };
</script>

<style scoped>
  .advanced-search {
    flex: 0 0 auto;
    padding: var(--spacing-unit-small);
  }

  .advanced-search-title {
    margin: 0 0 var(--spacing-unit-micro);
    font-size: 16px;
    font-weight: 700;
  }

  .advanced-search-button {
    background: var(--color-blue-100);
    color: #fff;
    width: 100%;
    display: block;
    border: 0;
    height: 32px;
    font-weight: 700;
    border-radius: 2px;
    padding: var(--spacing-unit-tiny) var(--spacing-unit-small);
  }
</style>

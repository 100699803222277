const font = {
  family: "Open Sans, sans-serif",
  size: {
    small: 12,
    medium: 14,
    large: 18,
  },
};

const colors = {
  legendMin: "#D9DFED",
  legendMax: "#1B2453",
  countryActive: "#6AD9C8",
  countryHover: "#00E8C6",
  countryHoverActive: "#00E8C6",
  buttonHover: "rgb(178, 194, 207)",
  mapBackground: "#ebedf1",
  black: "#000",
  white: "#fff",
};

const tooltip = {
  default: {
    getStrokeFromObject: true,
    getFillFromObject: false,
    background: {
      fillOpacity: 1,
      fill: colors.black,
      strokeWidth: 0,
    },
    label: {
      fill: colors.white,
      fontFamily: font.family,
      fontSize: font.size.medium,
    },
  },
  legend: {
    getStrokeFromObject: true,
    background: {
      fillOpacity: 0.75,
      fill: colors.white,
      strokeWidth: 0,
    },
    label: {
      fill: colors.black,
      fontFamily: font.family,
      fontSize: font.size.medium,
    },
  },
};

const settings = {
  disabledCountries: ["AQ"],
  legendContainer: {
    layout: "vertical",
    align: "left",
    valign: "bottom",
    dy: -36,
    dx: 12,
    background: {
      fill: colors.mapBackground,
      fillOpacity: 0.75,
    },
  },
  legendLabel: {
    marginBottom: 6,
    text: "Number of Projects",
    fontFamily: font.family,
    fontSize: font.size.medium,
    fontWeight: "700",
    align: "left",
  },
  heatLegendConfig: {
    orientation: "horizontal",
    layout: "vertical",
    width: 200,
    logarithmic: true,
    id: "heatLegend",
    valueAxis: {
      renderer: {
        labels: {
          template: {
            fontFamily: font.family,
            fontSize: font.size.small,
          },
        },
      },
      tooltip: tooltip.legend,
    },
  },
  zoomContainer: {
    layout: "horizontal",
    valign: "bottom",
    align: "center",
    height: 24,
    dy: -12,
    background: {
      fill: colors.mapBackground,
      fillOpacity: 0.75,
    },
  },
  zoomConfig: {
    layout: "horizontal",
    marginBottom: 0,
    slider: {
      width: 100,
      height: 24,
      background: {
        fill: colors.white,
        fillOpacity: 0.5,
      },
    },
    plusButton: {
      marginLeft: 3,
      height: 24,
      width: 24,
      contentAlign: "center",
      contentValign: "middle",
      label: {
        align: "center",
        valign: "middle",
        fontFamily: font.family,
        fontSize: font.size.large,
        fontWeight: "700",
      },
      background: {
        strokeWidth: 0,
        fill: colors.white,
      },
    },
    minusButton: {
      marginRight: 3,
      height: 24,
      width: 24,
      label: {
        align: "center",
        valign: "middle",
        fontFamily: font.family,
        fontSize: font.size.large,
        fontWeight: "700",
      },
      background: {
        strokeWidth: 0,
        fill: colors.white,
      },
    },
    thumb: {
      height: 24,
      width: 12,
      background: {
        strokeWidth: 0,
        fill: colors.white,
      },
    },
  },
  zoomHome: {
    height: 24,
    width: 24,
    marginLeft: "3",
    background: {
      strokeWidth: 0,
      fill: colors.white,
      defaultState: {
        properties: {
          fill: colors.white,
        },
      },
    },
    icon: {
      valign: "middle",
      align: "center",
      fill: colors.black,
      path:
        "M2 11.333V14h2.666l.00066667 2H1.33333333C.59695367 16 0 15.4030463 0 14.6666667v-3.3333334L2 11.333zm14 .0003333v3.3333334C16 15.4030463 15.4030463 16 14.6666667 16h-3.3333334L11.333 14H14v-2.667l2 .0003333zM8 5.33333333c1.47275933 0 2.6666667 1.19390734 2.6666667 2.66666667 0 1.47275933-1.19390737 2.6666667-2.6666667 2.6666667S5.33333333 9.47275933 5.33333333 8 6.52724067 5.33333333 8 5.33333333zM14.6666667 0C15.4030463 0 16 .59695367 16 1.33333333v3.33333334L14 4.666V2h-2.667l.0003333-2h3.3333334zM4.66666667 0L4.666 2H2v2.666l-2 .00066667V1.33333333C0 .59695367.59695367 0 1.33333333 0h3.33333334z",
    },
    tooltipText: "Zoom to 100% and center",
    tooltip: tooltip.default,
  },
  polygonTemplate: {
    tooltip: tooltip.default,
    tooltipText: "{name}: {value}",
    togglable: false,
    stroke: colors.mapBackground,
  },
  colors: colors,
};

export default settings;

<template>
  <div class="selection">
    <div class="selection-header">
      <h4 class="selection-title">Selection</h4>
      <span class="selection-clear" @click="$emit('clearAll')">clear all</span>
    </div>
    <div v-if="!selection.length" class="selection-no-items">
      No selection
    </div>
    <div v-else class="selection-items">
      <div
        class="selection-item"
        v-for="country in selection"
        :key="country.id"
      >
        {{ country.name }}
        <span
          class="selection-item-remove"
          @click="$emit('deselectCountry', country.id)"
          >×</span
        >
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "Selection",
    props: {
      selection: Array,
    },
  };
</script>

<style scoped>
  .selection {
    display: flex;
    flex-direction: column;
  }

  .selection-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: var(--spacing-unit);
  }

  .selection-title {
    margin: 0;
  }
  .selection-clear {
    font-size: 12px;
    cursor: pointer;
  }

  .selection-no-items {
    padding: var(--spacing-unit);
    padding-top: 0;
  }

  .selection-items {
    padding: var(--spacing-unit);
    padding-top: 0;
    max-height: 160px;
    overflow: auto;
    display: flex;
    flex-wrap: wrap;
    grid-gap: 4px;
  }

  .selection-item {
    padding: 3px var(--spacing-unit-tiny);
    display: inline-flex;
    border-radius: 20px;
    background: var(--color-light-blue-300);
    color: var(--color-blue-400);
    font-size: 12px;
  }

  .selection-item-remove {
    margin-left: var(--spacing-unit-micro);
    color: var(--color-blue-400);
    height: 16px;
    width: 16px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .selection-item-remove:hover {
    color: var(--color-black);
  }
</style>
